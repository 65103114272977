import React from "react";
import { graphql } from "gatsby";
import { SectionHomepagePicker } from "../sections/homepage-picker";
import { SectionSideBySide } from "../sections/side-by-side";
import { SectionCarousel } from "../sections/carousel";
import { SectionNikeiHeader } from "../sections/nikei-header";
import { SectionFullWidthImage } from "../sections/full-width-image";
import { SectionImageWithText } from "../sections/image-with-text";
import { Helmet } from "react-helmet";
import { SectionBooking } from "../sections/booking";
import { Footer } from "../components/footer";
import { Navigation } from "../components/navigation";

// ES6 modules
import sal from 'sal.js';
import 'sal.js/dist/sal.css';
import { SectionPractical } from "../sections/practical";

const SAL_OPTIONS = {
  'threshold': 0.1
}

export default class Page extends React.Component {
  sectionConductor(section) {
    switch (section.__typename) {
      case 'DatoCMS_SectionHomepagePickerRecord':
        return <SectionHomepagePicker {...section} key={section.id} />
      case 'DatoCMS_SectionSideBySideRecord':
        return <SectionSideBySide {...section} key={section.id} />
      case 'DatoCMS_SectionCarouselRecord':
        return <SectionCarousel {...section} key={section.id} />
      case 'DatoCMS_SectionNikeiHeaderRecord':
        return <SectionNikeiHeader {...section} key={section.id} />
      case 'DatoCMS_SectionFullWidthImageRecord':
        return <SectionFullWidthImage {...section} key={section.id} />
      case 'DatoCMS_SectionImageWithTextRecord':
        return <SectionImageWithText {...section} key={section.id} />
      case 'DatoCMS_SectionReservationRecord':
        return <SectionBooking {...section} key={section.id} />
      case 'DatoCMS_SectionPracticalRecord':
        return <SectionPractical {...section} key={section.id} />
      default:
        return false;
    }
  }

  componentDidMount () {
    const { extraClass } = this.props.data.dato.page;
    if (extraClass) document.body.classList.add(extraClass);
    sal(SAL_OPTIONS);

    setTimeout(() => sal(SAL_OPTIONS), 200);

    ;(function (d, s, id) {
      const el = d.getElementsByTagName(s)[0]
      if (d.getElementById(id) || el.parentNode == null) return
      var js = d.createElement(s)
      js.id = id
      js.src = 'https://sdk.zenchef.com/v1/sdk.min.js'
      el.parentNode.insertBefore(js, el)
    })(document, 'script', 'zenchef-sdk')
  }

  componentWillUnmount () {
    const { extraClass } = this.props.data.dato.page;
    if (extraClass) document.body.classList.remove(extraClass);
  }

  render = () => {
    const { navigation, footer } = this.props.data.dato;
    const { title, sections } = this.props.data.dato.page;

    return (
      <>
        <Navigation {...navigation} />
        <main>
          <Helmet>
            <title>{ `${title} | Stable Restaurant` }</title>
          </Helmet>
          { sections.map((section) => this.sectionConductor(section)) }
        </main>
        <div class="zc-widget-config" data-restaurant="374265" data-open="2000" />
        <Footer {...footer} />
      </>
    );
  };
}

export const query = graphql`
  query PageQuery($slug: String!, $language: DatoCMS_SiteLocale) {
    dato {
      navigation(locale: $language) {
        items {
          label
          target
        }
        logoLink
        buttonLabel
        buttonTarget
      }
      footer(locale: $language) {
        column1Title
        column1Body
        column2Title
        column2Body
        column3Title
        socialMediaLinks {
          title
          url
          icon {
            url
            local {
              childImageSharp {
                gatsbyImageData(width: 44)
              }
            }
          }
        }
        partnersTitle
        partners {
          label
          url
        }
      }
      page(filter: { slug: { eq: $slug } }, locale: $language) {
        title
        extraClass
        sections {
          __typename
          ... on DatoCMS_SectionHomepagePickerRecord {
            id
            blocks {
              title
              buttonLabel
              buttonTarget {
                slug
              }
              image {
                url
                local {
                  childImageSharp {
                    gatsbyImageData(width: 2000)
                  }
                }
              }
            }
          }
          ... on DatoCMS_SectionSideBySideRecord {
            id
            textLeft
            textRight
            extraClass
            icon {
              url
              local {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
          ... on DatoCMS_SectionCarouselRecord {
            id
            images {
              url
              local {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
          ... on DatoCMS_SectionNikeiHeaderRecord {
            id
            imageDesktop {
              url
              local {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
            imageMobile {
              url
              local {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
          ... on DatoCMS_SectionFullWidthImageRecord {
            id
            image {
              url
              local {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
          }
          ... on DatoCMS_SectionReservationRecord {
            columns {
              id
              title
              body
              buttonLabel
              buttonTarget
            }
            footer
          }
          ... on DatoCMS_SectionImageWithTextRecord {
            id
            title
            body
            image {
              url
              local {
                childImageSharp {
                  gatsbyImageData(width: 2000)
                }
              }
            }
            buttonLabel
            buttonTarget
            bottomNote
            centered
            flipped
            alignedToTop
            extraClass
            resengoForm {
              companyId
              language
              embedCode
            }
          }
          ... on DatoCMS_SectionPracticalRecord {
            id
            columns {
              id
              title
              body
              buttonLabel
              buttonTarget
            }
          }
        }       
      }
    }
  }
`;
